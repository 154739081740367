<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loader">
                <b-row>
                  <b-col lg="12" sm="12">
                      <b-table-simple v-if="Object.values(detailsData).length > 0" bordered hover small class="text-center">
                        <b-tr>
                          <b-th style="width:20%" class="text-right">{{ $t('portal.grade_from') }}</b-th>
                          <b-td style="width:30%" class="text-left">{{ $n(detailsData.grade_from)}}</b-td>
                          <b-th style="width:20%" class="text-right">{{$t('portal.grade_to')}}</b-th>
                          <b-td style="width:30%" class="text-left">{{ $n(detailsData.grade_to)}}</b-td>
                        </b-tr>
                      </b-table-simple>
                      <b-table-simple bordered hover small>
                        <thead>
                          <tr class="bg-primary text-center">
                            <th style="width:5%">{{ $t('globalTrans.sl_no') }}</th>
                            <th>{{ $t('elearning_config.room_type') }}</th>
                          </tr>
                        </thead>
                        <b-tbody v-if="detailsData?.details?.length > 0">
                            <b-tr v-for="(item, index) in detailsData.details" :key="index" class="text-center">
                                <b-td>{{ $n(index + 1) }}</b-td>
                                <b-td>{{ getRoomType(item.room_type) }}</b-td>
                            </b-tr>
                        </b-tbody>
                      </b-table-simple>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { gradeSetupShow } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    if (this.item) {
       this.getGradeSetupInfo()
      }
  },
  data () {
    return {
      loader: false,
      slOffset: 1,
      detailsData: {}
    }
  },
  computed: {
  },
  methods: {
    getRoomType (id) {
      const dataRoomType = this.$store.state.TrainingElearning.commonObj.roomTypeSetupList.filter(item => item.status === 1).find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return dataRoomType !== undefined ? dataRoomType.text_bn : ''
      } else {
        return dataRoomType !== undefined ? dataRoomType.text_en : ''
      }
    },
    async getGradeSetupInfo () {
      this.loader = true
      const resultData = await RestApi.getData(trainingElearningServiceBaseUrl, gradeSetupShow + '/' + this.item)
      if (resultData.success) {
        this.detailsData = resultData.data
        this.loader = false
      } else {
        this.detailsData = {}
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
